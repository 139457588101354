const Clock: React.FC<React.SVGProps<SVGSVGElement>> = ({ height = 24, width = 24, color = 'currentColor' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346627 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6568C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7601 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9976 5.87901 15.1539 3.84559 13.6542 2.34582C12.1544 0.84606 10.121 0.00242648 8 0ZM8 14.4C6.7342 14.4 5.49683 14.0246 4.44435 13.3214C3.39188 12.6182 2.57158 11.6186 2.08717 10.4492C1.60277 9.27972 1.47603 7.9929 1.72298 6.75142C1.96992 5.50994 2.57946 4.36957 3.47452 3.47451C4.36958 2.57946 5.50995 1.96992 6.75142 1.72297C7.9929 1.47603 9.27973 1.60277 10.4492 2.08717C11.6186 2.57157 12.6182 3.39187 13.3214 4.44435C14.0246 5.49682 14.4 6.7342 14.4 8C14.3981 9.69678 13.7232 11.3235 12.5233 12.5233C11.3235 13.7231 9.69679 14.3981 8 14.4ZM8.8 7.7992V4C8.8 3.78783 8.71572 3.58434 8.56569 3.43431C8.41566 3.28428 8.21218 3.2 8 3.2C7.78783 3.2 7.58435 3.28428 7.43432 3.43431C7.28429 3.58434 7.2 3.78783 7.2 4V8C7.20011 8.13175 7.23257 8.26146 7.29453 8.37773L8.49453 10.6168C8.59471 10.8039 8.76512 10.9435 8.96826 11.005C9.1714 11.0665 9.39064 11.0447 9.57774 10.9445C9.76484 10.8443 9.90448 10.6739 9.96595 10.4708C10.0274 10.2677 10.0057 10.0484 9.90547 9.86132L8.8 7.7992Z"
        fill={color}
      />
    </svg>
  )
}

export default Clock
