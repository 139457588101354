const Tag: React.FC<React.SVGProps<SVGSVGElement>> = ({ height = 24, width = 24, color = 'currentColor' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.50001 2.80141C3.36156 2.80141 3.22622 2.84248 3.11111 2.91944C2.99599 2.99639 2.90627 3.10577 2.85329 3.23374C2.80031 3.36172 2.78645 3.50253 2.81346 3.63839C2.84047 3.77424 2.90714 3.89903 3.00503 3.99698C3.10293 4.09492 3.22766 4.16163 3.36344 4.18865C3.49923 4.21567 3.63998 4.2018 3.76788 4.1488C3.89579 4.09579 4.00512 4.00602 4.08203 3.89085C4.15895 3.77568 4.2 3.64027 4.2 3.50176C4.2 3.31601 4.12625 3.13788 3.99498 3.00654C3.8637 2.87519 3.68566 2.80141 3.50001 2.80141ZM13.797 6.84943L7.161 0.224123C7.09573 0.153712 7.01669 0.097483 6.92878 0.0589291C6.84087 0.0203752 6.74598 0.000320626 6.65 1.17058e-05H2.45001C2.35788 -0.000521185 2.26656 0.0171452 2.18127 0.0519977C2.09599 0.0868503 2.01842 0.138204 1.95301 0.203113L0.203011 1.94698C0.138134 2.01242 0.0868069 2.09003 0.0519717 2.17536C0.0171366 2.26069 -0.000520924 2.35206 1.17e-05 2.44423V6.64632C0.00259014 6.83175 0.0750979 7.00936 0.203011 7.14357L6.846 13.7969C6.98014 13.9249 7.15766 13.9974 7.343 14C7.43512 14.0005 7.52645 13.9829 7.61173 13.948C7.69702 13.9132 7.77459 13.8618 7.84 13.7969L13.797 7.83692C13.8619 7.77148 13.9132 7.69387 13.948 7.60854C13.9829 7.52321 14.0005 7.43184 14 7.33967C13.9957 7.15667 13.9233 6.98187 13.797 6.84943ZM7.343 12.3192L1.40001 6.36618V2.73137L2.73001 1.40071H6.356L12.306 7.34667L7.343 12.3192Z"
        fill={color}
      />
    </svg>
  )
}

export default Tag
